import {Component, ElementRef, Input, viewChild} from '@angular/core';

@Component({
  selector: 'app-expandable-text',
  standalone: true,
  imports: [],
  templateUrl: './expandable-text.component.html',
  styleUrl: './expandable-text.component.css'
})
export class ExpandableTextComponent {
  // CSS height
  @Input({required: true}) maxHeight = '';
  protected text = viewChild<ElementRef>('text');
  protected textEl: HTMLElement | undefined;
  protected icon = viewChild<ElementRef>('icon');
  protected iconEl: HTMLElement | undefined;

  ngOnInit() {
    this.textEl = this.text()!.nativeElement;
    this.iconEl = this.icon()!.nativeElement;
  }

  toggleText(event: Event) {
    const wrapperHeight = this.textEl!.parentElement!.offsetHeight;
    const textHeight = this.textEl!.offsetHeight;

    if( wrapperHeight >= textHeight  ) {
      this.textEl!.parentElement!.style.height = this.maxHeight;
    } else {
      this.textEl!.parentElement!.style.height = textHeight + "px";
    }

    this.iconEl!.classList.toggle('-scale-y-100');
  }
}
