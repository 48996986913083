import {Component, Input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-form-control-text-field',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './form-control-text-field.component.html',
  styleUrl: './form-control-text-field.component.css'
})
export class FormControlTextFieldComponent {
  @Input({required: true}) name = '';
  @Input({required: true}) label = '';
  // @ts-ignore
  @Input({required: true}) control: FormControl;
  @Input({required: false}) controlError = '';
  @Input() defaultValue = '';

  ngOnInit() {
    setTimeout(() => {
      this.control.setValue(this.defaultValue);
    });
  }
}
