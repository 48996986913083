import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.css'
})
export class CheckboxComponent {
  @Input({required: true}) name = '';
  protected checked = false;

  protected change(event: Event) {
    this.checked = (event.target as HTMLInputElement).checked;
  }
}
