import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, Subject, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import * as constans from '../_constants/constants';
import {NotificationsResponse} from '../_interfaces/notifications.response';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public data$: Subject<NotificationsResponse> = new Subject();

  constructor(private httpClient: HttpClient) {
  }

  fetchAll() {
    return this.httpClient.get<NotificationsResponse>(environment.apiUrl + '/notifications', {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('token') ?? '')
    }).pipe(
      catchError(err => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      }),
      map(result => {
        this.data$.next(result);
      }));
  }

  unread(notificationId: string) {
    return this.httpClient.post(environment.apiUrl + '/notifications/' + notificationId + '/read', {}, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, localStorage.getItem('token') ?? '')
    }).pipe(
      catchError(err => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      }),
      map(result => {
        this.fetchAll().subscribe();
        return result;
      }));
  }

  unreadAll() {
    return this.httpClient.post(environment.apiUrl + '/notifications/readAll', {}, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, localStorage.getItem('token') ?? '')
    }).pipe(
      catchError(err => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      }),
      map(result => {
        return result;
      }));
  }
}
