<app--unauthorized-base>
  <h1 class="text-2xl font-bold mb-0.5">Wpisz kod weryfikacyjny</h1>
  <h2 class="text-gray-light">Wysłaliśmy Tobie smsem jednorazowy kod weryfikacyjny. Przepisz go poniżej.</h2>
  <form (submit)="submit($event)" class="grow flex flex-col">
    <div>
      <app-pin-pad [parentComponent]="this" [fullValidation]="this.fullValidation" (pinValue)="this.pinPadValue = $event" (error)="this.pinPadError = $event"/>
      <small *ngIf="this.pinPadError !== ''" class="text-red-500">{{ this.pinPadError }}</small>
    </div>
    @if (isSubmitted) {
      <div class="w-full flex items-center justify-center mt-10">
        <svg class="theme-icon" width="73" height="73" viewBox="0 0 73 73" xmlns="http://www.w3.org/2000/svg">
          <path d="M36.5 73C56.6584 73 73 56.6584 73 36.5C73 16.3416 56.6584 0 36.5 0C16.3416 0 0 16.3416 0 36.5C0 56.6584 16.3416 73 36.5 73ZM52.6113 29.7988L34.3613 48.0488C33.0211 49.3891 30.8539 49.3891 29.5279 48.0488L20.4029 38.9238C19.0627 37.5836 19.0627 35.4164 20.4029 34.0904C21.7432 32.7645 23.9104 32.7502 25.2363 34.0904L31.9375 40.7916L47.7637 24.9512C49.1039 23.6109 51.2711 23.6109 52.5971 24.9512C53.923 26.2914 53.9373 28.4586 52.5971 29.7846L52.6113 29.7988Z" />
        </svg>
      </div>
    } @else {
      <p *ngIf="!this.resendAuthCodeStatus" class="text-gray-dark mt-9">Kod nie dotarł? <a href="javascript:void(0)" (click)="resendAuthCode()" class="link-button-underline">wyślij ponownie</a></p>
      <p class="text-gray-dark mt-9"><a *ngIf="this.resendAuthCodeStatus">Kod wysłany ponownie!</a></p>
    }

    <button #submitButton type="submit" class="btn btn-secondary btn-text-shadow mt-auto w-full">dalej</button>
  </form>
</app--unauthorized-base>
