import {Component, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-finished',
  standalone: true,
  imports: [
    RouterLink,
    BaseComponent
  ],
  templateUrl: './finished.component.html',
  styleUrl: './finished.component.css'
})
export class FinishedComponent implements OnInit {
    ngOnInit(): void {
      sessionStorage.removeItem('registration')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('customerId')
    }
}
