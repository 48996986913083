<app--authorized-base>
  <app-page-title label="Zgody" routerLinkUrl="/welcome" />

  <main class="px-8 flex flex-col gap-y-5 pb-20 grow w-[48rem] max-w-full">
    <form action="/change-success" class="flex flex-col gap-y-6 grow">
      <app-checkbox name="field-1">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
        <a routerLink="details" class="regulamin-link link-button-underline !font-normal">dolore magna aliquyam erat, sed diam voluptua.</a>
      </app-checkbox>
      <app-checkbox name="field-2">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
        <a routerLink="details" class="regulamin-link link-button-underline !font-normal">dolore magna aliquyam erat, sed diam voluptua.</a>
      </app-checkbox>

      <button class="btn btn-secondary mt-auto" type="submit">
        zatwierdź zmianę danych
      </button>
    </form>
  </main>
</app--authorized-base>
