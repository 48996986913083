import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import * as constans from '../_constants/constants';
import {MeResponse} from '../_interfaces/me.response.interface';
import {Customer} from '../classes/customer';
import {jwtDecode} from 'jwt-decode';
import {CustomJwtPayload} from '../_interfaces/custom.jwt.payload';

@Injectable({
  providedIn: 'root'
})
export class CustomerServices {
  private customer: Customer = new Customer();
  private authenticated: boolean = false;

  constructor(private http: HttpClient) {}

  patchCustomer(data: any, customerId: string, token: string = '') {
    if (token === '') {
      token = localStorage.getItem('token') ?? '';
    }
    return this.http.patch(environment.apiUrl + '/customers/' + customerId, data, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.PATCH, token),
    });
  }

  getMe() {
    return this.http.get<MeResponse>(environment.apiUrl + '/me' , {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('token') ?? ''),
    });
  }

  public getCustomer(): Customer {
    return this.customer;
  }

  public loginWithToken(token: string): boolean {
    this.setCustomer(token);

    return this.customer.id !== '';
  }

  private setCustomer(token: string) {
    if (this.customer.id === '') {
      this.authenticated = true;

      let customer = new Customer();
      let local = jwtDecode(token) as CustomJwtPayload;

      customer.id = local.id;
      customer.name = local.name;
      customer.email = local.email;
      customer.clubId = local.clubId;
      this.customer = customer;
    }
  }

  getCustomers(value :string) {
    return this.http.get(environment.apiUrl + '/customers?phoneNumber=' + encodeURIComponent(value), {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('user_token') ?? ''),
    });
  }

  postInvite(data: any) {
    return this.http.post(environment.apiUrl + '/invite', data, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, localStorage.getItem('token') ?? ''),
    });
  }
}
