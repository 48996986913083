<app--unauthorized-base>
  <h1 class="text-2xl font-bold mb-0.5">Zaloguj się</h1>

  <form novalidate [formGroup]="this.loginForm" (submit)="submit($event)" class="grow flex flex-col">
    <div class="mt-8 flex flex-col gap-y-6">
      <app-form-control-text-field defaultValue="+48" [control]="this.loginForm.controls['emailOrPhone']" name="emailOrPhone" label="Nr telefonu" />
      <small class="text-red-500">{{ this.loginFormError }}</small>
    </div>
    <br />
    <div *ngIf="this.sendAuthCode" class="mt-4 flex flex-col gap-y-6">
      <h2 class="text-2xl font-bold mb-0.5">Wpisz kod weryfikacyjny</h2>
      <h2 class="text-gray-light">Wysłaliśmy Tobie mailem lub sms-em jednorazowy kod weryfikacyjny. Przepisz go poniżej.</h2>
      <app-pin-pad [parentComponent]="this" [fullValidation]="this.fullValidation" (pinValue)="this.pinValue = $event" (error)="this.pinPadError = $event"/>
      <small class="text-red-500">{{ this.pinPadError }}</small>
    </div>
    <app-form-alert [text]="this.confirmError" *ngIf="confirmError"></app-form-alert>
    <br />
    <button #submitButton type="submit" class="btn btn-secondary btn-text-shadow mt-auto">zaloguj</button>
  </form>
</app--unauthorized-base>
