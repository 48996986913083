import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';

@Injectable({
  providedIn: 'root'
})
export class CodesService {

  constructor(private httpClient: HttpClient) {
  }

  postCodes(data: any) {
    return this.httpClient.post(environment.apiUrl + '/codes', data, {
      headers: HeaderUtils.createHeaders('POST', localStorage.getItem('token') ?? ''),
    });
  }

  getCodes(clubId: any) {
    return this.httpClient.get(environment.apiUrl + '/codes?customer.clubId=' + clubId, {
      headers: HeaderUtils.createHeaders('GET', localStorage.getItem('user_token') ?? ''),
    });
  }
}
