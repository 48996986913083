<div id="page-wrapper" class="relative min-h-screen flex flex-col items-center brand-qsq">
  <div class="w-[48rem] max-w-full">
    <header class="w-full px-16 bg-transparent">
      <div class="bg-black flex justify-center items-end pb-6 pt-12 rounded-b-3xl">
        <a href="/" class="block">
          <img src="/assets/images/logo-concierge.svg" alt="Concierge" />
        </a>
      </div>
    </header>
    <div class="flex flex-col">
      <div class="my-8 text-center z-10 max-w-72 mx-auto">
        <h1 class="text-2xl font-bold text-white mb-3">Witaj w klubie rabatowym Końsierge</h1>
        <h2 class="text-white font-light">załóż konto i korzystaj z rabatów od wszystkich naszych marek</h2>
      </div>

      <app-slider [slidesPerPage]="1" [slidePxWidth]="364" [slidePxGap]="1" activePageBulletClass="bg-white">
        @for (i of Array(4); track $index) {
          <app-slide>
            <div class="mb-9">
              <img class="mx-auto" src="/assets/images/intro/logos/qsq.png" alt="Quiosque" />
            </div>
            <div class="relative aspect-square w-full max-w-[364px] border-[3px] border-white rounded-2xl flex items-end justify-center">
              <p class="text-2xl font-bold text-white mb-9 text-center z-10">Rabaty do -<span>30%</span></p>

              <img alt="" src="/assets/images/intro/modelka.png" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </div>
          </app-slide>
        }
      </app-slider>

      <div class="flex items-center justify-center gap-x-2 justify-self-end">
        <a routerLink="register" class="btn btn-primary-reversed">
          zarejestruj się
        </a>

        <a routerLink="login" class="btn btn-primary">
          zaloguj się
        </a>
      </div>

      <!-- fancy background, może trzeba to zamienić na img per slide? Nie byłem w stanie wyeksportować tego z projektu -->
      <div class="intro-slider-background absolute top-0 left-0 w-full h-full -z-10 overflow-hidden">
        <div style="height: 520px" class="absolute aspect-square rounded-full opacity-15 bg-gray-dark bottom-0 right-0 translate-y-1/3 translate-x-1/2 blur-lg"></div>
        <div style="height: 320px" class="absolute aspect-square rounded-full opacity-50 bg-white top-1/2 left-0 -translate-y-1/2 -translate-x-2/3 blur-[70px]"></div>
        <div style="height: 120px" class="absolute aspect-square rounded-full opacity-50 bg-white top-36 right-0 translate-x-1/2 blur-2xl"></div>
        <div style="height: 520px" class="absolute aspect-square rounded-full opacity-40 bg-black top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 blur-[100px]"></div>
      </div>
    </div>
  </div>

</div>
