import {Routes} from '@angular/router';
import {IndexComponent} from './unauthorized/index/index.component';
import {RegisterComponent} from './unauthorized/register/register.component';
import {AuthCodeComponent} from './unauthorized/auth-code/auth-code.component';
import {FinalizeComponent} from './unauthorized/finalize/finalize.component';
import {FinishedComponent} from './unauthorized/finished/finished.component';
import {LoginComponent} from './unauthorized/login/login.component';
import {WelcomeComponent} from './authorized/welcome/welcome.component';
import {BrandsComponent} from './authorized/brands/brands.component';
import {BrandPageComponent} from './authorized/brands/brand-page/brand-page.component';
import {NotificationsComponent} from './authorized/notifications/notifications.component';
import {DetailsChangeComponent} from './authorized/details-change/details-change.component';
import {ChangeSuccessComponent} from './authorized/change-success/change-success.component';
import {AgreementsComponent} from './authorized/agreements/agreements.component';
import {DetailsComponent} from './authorized/agreements/details/details.component';
import {AuthGuard} from './_guards/auth.guard';
import {NotfoundComponent} from './common/notfound/notfound.component';
import {UserComponent} from './authorized/user/user.component';
import {UserLoginComponent} from './unauthorized/user-login/user-login.component';
import {InviteComponent} from './authorized/invite/invite.component';

export const routes: Routes = [
  { path: '', component: IndexComponent, pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard]},
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'change-success', component: ChangeSuccessComponent, canActivate: [AuthGuard] },
  { path: 'details-change', component: DetailsChangeComponent, canActivate: [AuthGuard] },
  { path: 'invite', component: InviteComponent, canActivate: [AuthGuard] },
  { path: 'agreements', canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
    { path: '', component: AgreementsComponent },
    { path: 'details', component: DetailsComponent },
    { path: '**', redirectTo: '/agreements' },
  ] },

  { path: 'brands',canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: '', component: BrandsComponent },
      { path: 'brand-page', component: BrandPageComponent },
      { path: '**', redirectTo: '/brands' },
  ] },

  { path: 'login', component: LoginComponent },
  { path: 'register', children: [
    { path: '', component: RegisterComponent },
    { path: 'auth-code', component: AuthCodeComponent },
    { path: 'finalize', component: FinalizeComponent },
    { path: 'finished', component: FinishedComponent },
    { path: '**', redirectTo: '/register' },
  ] },
  { path: 'user', children: [
      {path: '', component: UserComponent},
      { path: 'login', component: UserLoginComponent },
      { path: '**', redirectTo: '/user' },
    ]},
  { path: '**', component: NotfoundComponent},
];
