<app--authorized-base class="brand-qsq">
  <app-page-title label="Poznaj nasze marki" routerLinkUrl="/welcome" />
  <div class="px-12 flex flex-col justify-center gap-y-5 pb-20 w-[48rem] max-w-full">
    @for (i of companies; track $index) {
      <a routerLink="brand-page" [queryParams]="{name: i.name}" class="flex h-[120px] items-center justify-center w-full bg-white shadow-[0px_0px_25px_#0000001A] rounded-2xl">
        <img src="/assets/{{i.logo.filePath}}" alt="Logo {{i.logo.filePath}}"/>
      </a>
    }
  </div>
</app--authorized-base>
