<div class="overflow-hidden">
  <div #sliderWrapper (window:resize)="resizeWindow()" (touchstart)="startSwipe($event)" (touchmove)="moveSwipe($event)" (touchend)="endSwipe($event)" class="flex opacity-0">
    <ng-content/>
  </div>
  <div class="slider__dots flex w-full justify-center gap-x-3 mt-8 mb-9">
    @for( i of Array(pageCount); track $index) {
      <button (click)="changeActivePage($index)" type="button" [class]="currentPage === $index ? activePageBulletClass : 'bg-gray-darker opacity-30'" class="rounded-full w-3 h-3">
        <span class="sr-only">slide {{$index}}</span>
      </button>
    }
  </div>
</div>
