import { Component } from '@angular/core';
import {RouterLink} from '@angular/router';
import {SliderComponent} from '../../common/slider/slider.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';

@Component({
  selector: 'app-index',
  standalone: true,
  imports: [
    RouterLink,
    SliderComponent,
    SlideComponent
  ],
  templateUrl: './index.component.html',
  styleUrl: './index.component.css'
})
export class IndexComponent {

  protected readonly Array = Array;
}
