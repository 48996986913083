import {Component, ElementRef, viewChild} from '@angular/core';

@Component({
  selector: 'app-slide',
  standalone: true,
  imports: [],
  templateUrl: './slide.component.html',
  styleUrl: './slide.component.css'
})
export class SlideComponent {
  public content = viewChild<ElementRef>('content');
  width = 0;
  gap = 0;
}
