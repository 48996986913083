<div id="page-wrapper" [class]="pageWrapperClasses" class="relative min-h-screen flex flex-col brand-qsq">

  <header id="header--logged-in" [class]="headerClasses" class="sticky top-0 w-full px-8 py-9 flex items-center justify-between bg-brand-dark drop-shadow-lg z-50">
    <a routerLink="/welcome">
      <div>
        <img src="/assets/images/logo-concierge.svg" />
      </div>
    </a>
  </header>

  <!-- drawer -->
  <div #menuDrawer class="menu-drawer fixed top-[101px] bottom-0 left-0 w-full h-full min-h-screen bg-gradient-to-b from-brand-dark to-brand-light px-8 py-3 transition-transform translate-x-full z-50">
    <nav role="menu" aria-labelledby="menubutton" class="flex flex-col text-white">
      <a role="menuitem" routerLink="/welcome" class="py-5 border-b border-b-white/30 text-lg pl-7">Ekran główny</a>
      <a role="menuitem" routerLink="/brands" class="py-5 border-b border-b-white/30 text-lg pl-7">Nasze marki</a>
      <a role="menuitem" routerLink="/details-change" class="py-5 border-b border-b-white/30 text-lg pl-7">Zmiana danych</a>
      <a role="menuitem" routerLink="/agreements" class="py-5 border-b border-b-white/30 text-lg pl-7">Zgody marketingowe</a>
      <a role="menuitem" href="#" class="py-5 border-b border-b-white/30 text-lg pl-7">Zaproś do klubu</a>
    </nav>
  </div>
  <main class="flex flex-col items-center grow bg-white">
    <ng-content/>
  </main>
</div>
