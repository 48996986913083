<div #qrHeader class="overflow-hidden max-h-48">
  <div id="heading-background" class="absolute top-0 left-0 w-full h-[510px] -z-10">
    <img src="/assets/images/main/background.png" class="object-cover w-full h-full object-left"  alt=""/>
  </div>

  <div class="text-center z-10 max-w-60 mx-auto">
    <h1 class="text-2xl font-bold text-white mb-3">Cześć {{ this.user.name }}!</h1>
    <h2 class="text-white font-light mb-7">Pokaż swój kod sprzedawcy aby skorzystać z promocji</h2>

    <canvas #qrCode id="myCanvas" width="208" height="208" class="scale-50 origin-top transition-all max-w-52 mx-auto aspect-square"></canvas>

    <div (click)="toggleQr()" #closeQr class="hidden">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mx-auto size-8 my-4 opacity-40">
        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
      </svg>
    </div>
  </div>
</div>
<div (click)="toggleQr()" #openQr class="bg-transparent h-16 relative transition-all">
  <div class="bg-white absolute top-0 left-0 right-0 h-10 w-full" style="clip-path: ellipse(60% 100% at 50% 100%);"></div>
  <div class="bg-white absolute bottom-0 left-0 h-6 w-full flex justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="theme-icon-dark size-10 relative bottom-4">
      <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
    </svg>
  </div>
</div>
