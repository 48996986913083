<label for="{{name}}" class="w-full flex items-start gap-x-3">
    @if(checked) {
        <span class="w-6 h-6 border-2 text-gray-light shrink-0 rounded border-brand-light bg-brand-light cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-white">
                <path d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" />
            </svg>
        </span>
    } @else {
        <span class="w-6 h-6 border-2 text-gray-light shrink-0 rounded border-gray-light cursor-pointer">
        </span>
    }
  <input name="{{name}}" id="{{name}}" (change)="change($event)" type="checkbox" class="absolute opacity-0 h-0 w-0" />
  <span class="text-gray-light text-sm">
      <ng-content />
  </span>
</label>
