import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CompaniesResponse} from '../_interfaces/companies.response.interface';

@Injectable({
  providedIn: 'root'
})
export class CompanyServices {
  constructor(private http: HttpClient) { }

  public getCompanies(brand: boolean | null = null, name:string |null = null): Observable<CompaniesResponse[]> {
    let queryParams = '?';
    if (brand !== null) {
      queryParams += 'brand=' + brand + '&';
    }
    if (name !== null) {
      queryParams += 'name=' + name;
    }

    if (queryParams === '?') {
      queryParams = '';
    }

    return this.http.get<CompaniesResponse[]>(environment.apiUrl + '/companies' + queryParams);
  }
}
