import {Component, ViewChild, signal, effect, Input} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {GoogleMapsModule, MapInfoWindow} from '@angular/google-maps';
import {MapMarkerInterface}  from '../../_interfaces/map.marker.interface';
import {ShopsResponse} from '../../_interfaces/shops.response.interface';

@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: './map-component.component.html',
  imports: [
    CommonModule,
    GoogleMapsModule,
    RouterModule,
  ],
  styleUrls: ['./map-component.component.css']
})
export class MapComponentComponent {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  center: google.maps.LatLngLiteral = { lat: 53.11777728011778, lng: 18.009626955768983 };
  zoom = 6;
  @Input() markers: MapMarkerInterface[] = [];
  selectedShop = signal<ShopsResponse | null>(null);

  constructor() {
  }

  openInfoWindow(shop: ShopsResponse, marker: any): void {
    this.selectedShop.set(shop);
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }
}
