<app--authorized-base pageWrapperClasses="welcome-page">
  <app-qrcode [user]="this.customerService.getCustomer()" class="w-full"/>
  <div *ngIf="campaigns" class="w-full bg-white">

    <h2 class="font-bold text-2xl text-qsq-medium w-full text-center py-10">Aktualne promocje</h2>

    <div class="max-w-[1094px] mx-auto">
      <app-slider [slidePxWidth]="320" [slidesPerPage]="3" [slidePxGap]="12">
        @for (i of this.campaigns; track $index) {
          <app-slide>
            <div class="slide">
              <img src="/assets/{{i.company.logo.filePath}}" alt="Logo {{i.company.name}}" class="mx-auto mb-5" />
              <div class="group relative bg-gray-lighter rounded-3xl w-80 flex flex-col items-center py-8 px-10
                                    snap-center shrink-0"
              >
                <img src="/assets/images/main/slider/placeholder.png" alt="" />
                <p class="text-qsq-medium mt-4 mb-6">{{i.campaign.shortText}}</p>
                <p class="text-qsq-medium mt-4 mb-6">{{i.campaign.longText}}</p>
                <button *ngIf="i.codes.length == 0" (click)="takeCoupon(i.id)" class="btn btn-primary w-full !bg-qsq-medium !text-white">wykorzystaj</button>
                <div *ngIf="i.codes.length > 0" class="text-center">
                  kod aktywowano
                </div>
              </div>
            </div>
          </app-slide>
        }
      </app-slider>
    </div>
  </div>
</app--authorized-base>
