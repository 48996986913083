import {Component, Input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-form-control-date-field',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './form-control-date-field.component.html',
  styleUrl: './form-control-date-field.component.css'
})
export class FormControlDateFieldComponent {
  @Input({required: true}) name = '';
  @Input({required: true}) label = '';
  // @ts-ignore
  @Input({required: true}) control: FormControl;
  @Input({required: false}) controlError = '';
}
