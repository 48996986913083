import {Component, Input} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-form-alert',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './form-alert.component.html',
  styleUrl: './form-alert.component.css'
})
export class FormAlertComponent {
  @Input({required: true}) text = '';
}
