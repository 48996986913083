import { Component } from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';

@Component({
  selector: 'app-change-success',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent
  ],
  templateUrl: './change-success.component.html',
  styleUrl: './change-success.component.css'
})
export class ChangeSuccessComponent {

}
