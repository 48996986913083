import {Component, ElementRef, Input, viewChild} from '@angular/core';

@Component({
  selector: 'app-checkbox-agreement',
  standalone: true,
  imports: [],
  templateUrl: './agreement.component.html',
  styleUrl: './agreement.component.css'
})
/**
 * <main> tag needs to be an ancestor for it to work as intended.
 *
 * It was made specifically for unauthorized part of the website but
 * most certainly can be used elsewhere given similar template.
 */
export class AgreementComponent {
  @Input({required: true}) label = '';
  protected agreement = viewChild<ElementRef>('agreement');
  protected agreementEl: HTMLElement | undefined;
  protected main = document.getElementsByTagName('main');

  ngOnInit() {
    // Set higher in hierarchy to stop label click
    const el = this.agreement()!.nativeElement as HTMLElement;
    this.agreementEl = el;
    this.agreementEl.parentElement!.parentElement!.parentElement!.parentElement!.appendChild(el);
  }

  public show() {
    this.toggleVisibility(this);
    setTimeout(() => {
      this.main[0].addEventListener('click', () => this.toggleVisibility(this), {once: true});
    })
  }

  private toggleVisibility(comp: AgreementComponent) {
    comp.main[0].classList.toggle('-translate-x-full');
    comp.agreementEl!.classList.toggle('hidden');
    comp.agreementEl!.classList.toggle('translate-x-full');
  }
}
