<app--authorized-base>
  <app-page-title label="Zaproszenie do klubu" routerLinkUrl="/welcome" />
  <div class="w-10/12 md:w-6/12">
  <form novalidate [formGroup]="this.inviteForm" class="grow flex flex-col">
    <div class="mt-8 flex flex-col gap-y-6">
      <app-form-control-text-field name="first-name" [control]="inviteForm.controls['name']" label="Imię" controlError="Imię jest wymagane" />
      <app-form-control-text-field name="last-name" [control]="inviteForm.controls['surname']" label="Nazwisko" controlError="Nazwisko jest wymagane" />
      <app-form-control-text-field name="email" [control]="inviteForm.controls['email']" label="Adres email" controlError="Adres email jest wymagany" />
    </div>
    <br /><br />
    <app-form-alert *ngIf="this.inviteFormSuccess" text="Zaproszenie zostało wysłane." />
  </form>
    <button (click)="send()" [disabled]="this.submitted" class="btn btn-secondary btn-text-shadow mt-auto w-full disabled:opacity-30 disabled:cursor-not-allowed">Wyślij zaproszenie</button>
  </div>
</app--authorized-base>
