import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  GuardResult,
  MaybeAsync, Router,
  RouterStateSnapshot
} from '@angular/router';
import {AuthServices} from '../_services/auth.services';
import {CustomerServices} from '../_services/customer.services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private customerService: CustomerServices, private router: Router, private authService: AuthServices) {

    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
      return this.checkAuth();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
      return this.checkAuth();
    }

    checkAuth(){
      let token = this.authService.getToken();

      if (token) {
        let auth = this.customerService.loginWithToken(token);
        if (auth) {
          return true;
        }
      }

      return this.router.parseUrl('');
    }
}
