import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import {CampaignResponse} from '../_interfaces/campaign.response.interface';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private httpClient: HttpClient) {
  }

  getCampaigns(useToken: boolean = false): Observable<CampaignResponse[]> {
    if (useToken) {
      return this.httpClient.get<CampaignResponse[]>(environment.apiUrl + '/campaigns', {
        headers: HeaderUtils.createHeaders('', localStorage.getItem('token') ?? ''),
      }).pipe(
        map((data: CampaignResponse[]) => {
          let tempData: any[] = [];
          for (const tempDatum of data) {
            if (tempDatum.campaign !== undefined) {
              tempData.push(tempDatum);
            }
          }
          return tempData;
        })
      );
    }

    return this.httpClient.get<CampaignResponse[]>(environment.apiUrl + '/campaigns').pipe(
      map((data: CampaignResponse[]) => {
        let tempData: any[] = [];
        for (const tempDatum of data) {
          if (tempDatum.campaign !== undefined) {
            tempData.push(tempDatum);
          }
        }
        return tempData;
      })
    );
  }
}
