import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import * as constans from '../_constants/constants';

@Injectable({
  providedIn: 'root'
})
export class HeaderUtils {
  private static headers: HttpHeaders;

  public static createHeaders(method: string = '', bearer: string = '') {
    this.headers = new HttpHeaders();
    if (method === constans.HTTP_METHOD.GET) {
      this.headers = this.headers.append('Content-Type', 'application/json');
    } else if (method === constans.HTTP_METHOD.PATCH) {
      this.headers = this.headers.append('Content-Type', 'application/merge-patch+json');
    }

    if (bearer !== '') {
      this.headers = this.headers.append('Authorization', 'Bearer ' + bearer);
    }

    return this.headers;
  }
}
