import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CompaniesResponse} from '../_interfaces/companies.response.interface';
import {ShopsResponse} from '../_interfaces/shops.response.interface';

@Injectable({
  providedIn: 'root'
})
export class ShopServices {
  constructor(private http: HttpClient) { }

  public getShops(): Observable<ShopsResponse[]> {
    return this.http.get<ShopsResponse[]>(environment.apiUrl + '/shops');
  }
}
