<div style="height: {{ maxHeight }};" class="transition-all overflow-hidden">
  <div #text>
    <ng-content/>
  </div>
</div>
<div (click)="toggleText($event)" class="pt-4">
  <svg #icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mx-auto">
    <path stroke-width="3" stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
  </svg>
</div>
