import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {CheckboxComponent} from '../../common/checkbox/checkbox.component';
import {AgreementComponent} from '../../common/checkbox/agreement/agreement.component';
import {Router, RouterLink} from '@angular/router';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {FormControlDateFieldComponent} from '../../common/form-control-date-field/form-control-date-field.component';
import {CustomerServices} from '../../_services/customer.services';
import {
  GooglePlacesAutocompleteComponent
} from '../../common/google-places-autocomplete/google-places-autocomplete.component';

@Component({
  selector: 'app-finalize',
  standalone: true,
  imports: [
    BaseComponent,
    CheckboxComponent,
    AgreementComponent,
    ReactiveFormsModule,
    FormControlTextFieldComponent,
    FormControlDateFieldComponent,
    RouterLink,
    GooglePlacesAutocompleteComponent
  ],
  templateUrl: './finalize.component.html',
  styleUrl: './finalize.component.css'
})
export class FinalizeComponent implements OnInit {
  protected submitButton = viewChild<ElementRef>('submitButton');
  protected postCodeEl!: HTMLInputElement;
  protected cityEl!: HTMLInputElement;
  public finalizeForm: any;
  public postCodeError: string = '';
  public customerId: string = '';

  constructor(formBuilder: FormBuilder, private router: Router, private customerService: CustomerServices) {
    this.finalizeForm = formBuilder.group({
      street: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern('^\\d{2}-\\d{3}$')]],
      city: ['', Validators.required],
      birthDate: ['', Validators.required],
    });
    this.finalizeForm.valueChanges.subscribe({
      next: (value: string) => {
        this.validation()
      }
    })
  }

  ngAfterViewInit() {
    this.postCodeEl = document.getElementById('postCode') as HTMLInputElement;
    this.cityEl = document.getElementById('city') as HTMLInputElement;

    this.finalizeForm.controls['street'].valueChanges.subscribe({
      next: () => {
        this.postCodeEl.removeAttribute('disabled');
        this.cityEl.removeAttribute('disabled');
      }
    })
  }

  ngOnInit(): void {
    this.customerId = sessionStorage.getItem('customerId') ?? '';
    if (this.customerId === '') {
      this.router.navigate(['register']);
    }
  }

  validation() {
    let postCodeErrors = this.finalizeForm.controls['postCode'].errors;
    if (postCodeErrors?.required === true) {
      this.postCodeError = "Kod pocztowy jest wymagany jest wymagany"
    }
    if (postCodeErrors?.pattern instanceof Object) {
      this.postCodeError = "To nie jest poprawny kod pocztowy, przykładowy: 62-200"
    }

    if (postCodeErrors === null) {
      this.postCodeError = "";
    }
  }

  setAddressFromGooglePlace(place: google.maps.places.PlaceResult) {
    const streetName = GooglePlacesAutocompleteComponent.findAddressComponent(place, 'route') ?? "";
    const streetNumber = GooglePlacesAutocompleteComponent.findAddressComponent(place, 'street_number') ?? "";
    this.finalizeForm.controls['street'].setValue(streetName + " " + streetNumber);
    this.finalizeForm.controls['street'].markAsTouched();
    this.finalizeForm.controls['street'].markAsDirty();

    this.finalizeForm.controls['postCode'].setValue(GooglePlacesAutocompleteComponent.findAddressComponent(place, 'postal_code'));
    this.finalizeForm.controls['postCode'].markAsTouched();
    this.finalizeForm.controls['postCode'].markAsDirty();
    this.postCodeEl.setAttribute("disabled", '');

    this.finalizeForm.controls['city'].setValue(GooglePlacesAutocompleteComponent.findAddressComponent(place, 'locality'));
    this.finalizeForm.controls['city'].markAsTouched();
    this.finalizeForm.controls['city'].markAsDirty();
    this.cityEl.setAttribute("disabled", '');
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    for (const i in this.finalizeForm.controls) {
      this.finalizeForm.controls[i].markAsTouched();
      this.finalizeForm.controls[i].markAsDirty();
      this.validation()
    }

    if (this.finalizeForm.status === 'VALID') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');
        this.customerService.patchCustomer(this.finalizeForm.value, this.customerId, sessionStorage.getItem('token') ?? '').subscribe({
          next: (data) => {
            this.submitButton()!.nativeElement.classList.toggle('opacity-30');
            this.router.navigate(['register/finished']);
          }
        });
    }
  }
}
