import {Component, Input} from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-page-title',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.css',
  host: {'class': 'w-[48rem] max-w-full'}
})
export class PageTitleComponent {
  @Input({required: true}) label = '';
  /**
   * Router link path that will be pointed by "Back" button
   */
  @Input({required: true}) routerLinkUrl = '';
}
