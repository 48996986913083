import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import {InvoicesResponse} from '../_interfaces/invoices.response';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private httpClient: HttpClient) {
  }

  getInvoices(clubId: any) {
    return this.httpClient.get<InvoicesResponse[]>(environment.apiUrl + '/invoices?customer.clubId=' + clubId, {
      headers: HeaderUtils.createHeaders('GET', localStorage.getItem('user_token') ?? ''),
    });
  }
}
