<app--authorized-base>
  <app-page-title label="Zmień dane" routerLinkUrl="/welcome" />

  <div class="px-8 flex flex-col justify-center gap-y-5 pb-20 w-[48rem] max-w-full">
    <form (submit)="onSubmit($event)" class="flex flex-col gap-y-6">
      <app-form-control-text-field [control]="f.name" name="name" label="Imię" controlError="Imię jest wymagane" />
      <app-form-control-text-field [control]="f.surname"  name="surname" label="Nazwisko" controlError="Nazwisko jest wymagane" />
      <app-form-control-text-field [control]="f.street"  name="street" label="Ulica" controlError="Ulica jest wymagana" />
      <app-form-control-text-field [control]="f.postCode"  name="postCode" label="Kod pocztowy" controlError="Kod pocztowy jest wymagany" />
      <app-form-control-text-field [control]="f.city"  name="city" label="Miasto" controlError="Miasto jest wymagane" />
      <app-form-control-date-field [control]="f.birthDate" name="birthDate" label="Data urodzenia" controlError="Data urodzenia jest wymagana" />
      <app-form-alert *ngIf="this.formSuccess" text="Dane zostały zmienione." />
      <button #submitButton class="btn btn-secondary mt-7">
        zatwierdź zmianę danych
      </button>
    </form>
  </div>
</app--authorized-base>
