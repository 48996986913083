<div class="flex justify-between items-center px-8 my-12">
  <h1 class="text-2xl text-gray-dark font-bold">{{ label }}</h1>

  <a routerLink="{{ routerLinkUrl }}" class="flex justify-center items-center text-gray-dark font-bold text-lg">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" class="size-6 stroke-black">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>
    <span>wróć</span>
  </a>
</div>
