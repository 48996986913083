import { Component } from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {CheckboxComponent} from '../../common/checkbox/checkbox.component';
import {RouterLink} from '@angular/router';
import {PageTitleComponent} from '../page-title/page-title.component';

@Component({
  selector: 'app-agreements',
  standalone: true,
  imports: [
    BaseComponent,
    CheckboxComponent,
    RouterLink,
    PageTitleComponent
  ],
  templateUrl: './agreements.component.html',
  styleUrl: './agreements.component.css'
})
export class AgreementsComponent {

}
