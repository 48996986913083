import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {FormBuilder, Validators} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {CustomerServices} from '../../_services/customer.services';
import {FormControlDateFieldComponent} from '../../common/form-control-date-field/form-control-date-field.component';
import moment from 'moment';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-details-change',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent,
    FormControlTextFieldComponent,
    FormControlDateFieldComponent,
    FormAlertComponent,
    NgIf
  ],
  templateUrl: './details-change.component.html',
  styleUrl: './details-change.component.css'
})
export class DetailsChangeComponent implements OnInit {

  protected submitButton = viewChild<ElementRef>('submitButton');
  protected readonly Array = Array;
  public detailChangeForm: any;
  private customerId: string = '';
  public formSuccess: boolean = false;

  get f() {
    return this.detailChangeForm.controls
  }

  constructor(formBuilder: FormBuilder, private customerService: CustomerServices) {
    this.detailChangeForm = formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      street: [''],
      city: [''],
      postCode: [''],
      birthDate: [''],
    });
  }

  ngOnInit(): void {
    this.customerService.getMe().subscribe(
      data => {
        this.f.name.setValue(data.name);
        this.f.surname.setValue(data.surname);
        this.f.city.setValue(data.city);
        this.f.street.setValue(data.street);
        this.f.postCode.setValue(data.postCode);
        this.f.birthDate.setValue((moment(data.birthDate)).format('YYYY-MM-DD'));
        this.customerId = data.id
      }
    )
  }

  protected readonly onsubmit = onsubmit;

  onSubmit(event: Event) {
    event.preventDefault();
    this.formSuccess = false;
    if (this.detailChangeForm.status === 'VALID') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');
      this.customerService.patchCustomer(this.detailChangeForm.value, this.customerId).subscribe({
        next: () => {
          this.submitButton()!.nativeElement.classList.toggle('opacity-30');
          this.formSuccess = true;
        }
      })
    }
  }
}
