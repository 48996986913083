import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import { QrcodeComponent } from '../qrcode/qrcode.component';
import {SliderComponent} from '../../common/slider/slider.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';
import {CampaignsService} from '../../_services/campaigns.service';
import {CampaignResponse} from '../../_interfaces/campaign.response.interface';
import {NgIf} from '@angular/common';
import {CodesService} from '../../_services/codes.service';
import {CustomerServices} from '../../_services/customer.services';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    BaseComponent,
    QrcodeComponent,
    SliderComponent,
    SlideComponent,
    NgIf
  ],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent implements OnInit {

  protected readonly Array = Array;
  public campaigns!: CampaignResponse[];

  constructor(public customerService:CustomerServices, private campaignService:CampaignsService, private codesService:CodesService) {
  }

  ngOnInit(): void {
    this.campaignService.getCampaigns(true).subscribe({
      next: data => {
          this.campaigns = data;
      }
    })
    }

  takeCoupon(id: string) {
    this.codesService.postCodes({
      campaignCompany: "/api/campaign_companies/" + id,
    }).subscribe({
      next: data => {
        this.campaignService.getCampaigns(true).subscribe({
          next: data => {
            this.campaigns = data;
          }
        })
      }
    });
  }
}
