<div class="flex flex-col w-full relative pt-5">
  <input [formControl]="control" id="{{name}}" type="text" name="{{name}}" class="peer w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-brand-medium disabled:opacity-30 disabled:cursor-not-allowed" placeholder="{{label}}" />
  <label for="{{name}}" class="absolute left-0 -top-3.5 text-sm transition-all peer-placeholder-shown:text-base
                        peer-placeholder-shown:text-gray-light peer-placeholder-shown:top-2 peer-focus:-top-3.5
                        peer-focus:text-gray-600 peer-focus:text-sm"
  >
    {{label}}
  </label>
  <small *ngIf="control.invalid && control.dirty" class="text-red-500">{{ controlError }}</small>
</div>
