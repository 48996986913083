import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForOf, NgIf} from '@angular/common';
import {BaseUserComponent} from '../base-user/base-user.component';
import JsBarcode from 'jsbarcode';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {UserServices} from '../../_services/user.service';
import {CustomerServices} from '../../_services/customer.services';
import {CodesService} from '../../_services/codes.service';
import {InvoicesService} from '../../_services/invoices.service';
import moment from 'moment';
import {InvoicesResponse} from '../../_interfaces/invoices.response';

@Component({
  selector: 'app-welcome-user',
  standalone: true,
  imports: [
    NgIf,
    BaseUserComponent,
    FormsModule,
    ReactiveFormsModule,
    NgForOf
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class UserComponent implements AfterViewInit,OnInit {
  public clubId: any;
  public searchForm: any;
  public searchFormError: string = '';
  public codes: any;
  public invoices: InvoicesResponse[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserServices,
    private router: Router,
    private formBuilder: FormBuilder,
    private customerService: CustomerServices,
    private codesService: CodesService,
    private invoiceServices: InvoicesService
  ) {
    this.clubId = this.route.snapshot.queryParams['club'];
    this.searchForm = this.formBuilder.group({
      phone: new FormControl('', [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{5}$')]),
    })
    this.searchForm.controls['phone'].setValue('+48');
    this.searchForm.valueChanges.subscribe({
      next: (value: any) => {
        this.validation();
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.clubId) {
      JsBarcode('#barcode', this.clubId);
      this.codesService.getCodes(this.clubId).subscribe(codes => {
        this.codes = codes;
      });
      this.invoiceServices.getInvoices(this.clubId).subscribe(invoices => {
        this.invoices = invoices;
      }
      )
    }
  }

  ngOnInit(): void {
    this.userService.loginWithToken(this.userService.getToken() ?? '');
    let user = this.userService.getUser();

    if (user.email === '') {
      this.router.navigate(['/user/login']);
    }
  }

  search() {
    if (this.searchForm.status === 'VALID') {
      let phone = this.searchForm.controls['phone'].value;
      this.customerService.getCustomers(phone).subscribe({
        next: (data: any) => {
          if (data[0]?.clubId) {
            this.router.navigate(['/user'], {queryParams: {club: data[0].clubId}}).then(() => {
              window.location.reload();
            });
          } else {
            this.router.navigate(['/user']);
            this.clubId = '';
            this.searchFormError = 'Nie znaleziono Klienta po numerze telefonu.';
          }
        }
      })
    }
  }

  private validation() {
    let phoneErrors = this.searchForm.controls['phone'].errors;

    if (phoneErrors?.required === true) {
      this.searchFormError = "Numer telefonu jest wymagany"
    }
    if (phoneErrors?.pattern instanceof Object) {
      this.searchFormError = "To nie jest poprawny numer telefonu komórkowego, przykładowy: +48501501501"
    }
    if (phoneErrors === null) {
      this.searchFormError = "";
    }

  }

  protected readonly moment = moment;
  protected readonly Array = Array;
}
