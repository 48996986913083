import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment} from '../../environments/environment';
import {RegisterResponse} from "../_interfaces/register.response.interface";
import {RegisterRequest} from "../_interfaces/register.request.interface";
import {Observable} from 'rxjs';
import {ConfirmPhoneRequest} from '../_interfaces/confirm.phone.request.interface';
import {ConfirmPhoneResponse} from '../_interfaces/confirm.phone.response.interface';
import {ResendAuthCodeRequest} from '../_interfaces/resend.auth.code.request.interface';
import {ResendAuthCodeResponse} from '../_interfaces/resend.auth.code.response.interface';
import {AuthRequestRequest} from '../_interfaces/auth.request.request.interface';
import {AuthRequestResponse} from '../_interfaces/auth.request.response.interface';
import {AuthConfirmRequest} from '../_interfaces/auth.confirm.request.interface';
import {AuthConfirmResponse} from '../_interfaces/auth.confirm.response.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  constructor(private http: HttpClient) {}

  register(data: RegisterRequest): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(environment.apiUrl + '/registration-by-phone', data);
  }

  confirmPhone(data: ConfirmPhoneRequest) {
    return this.http.put<ConfirmPhoneResponse>(environment.apiUrl + '/confirm-phone', data);
  }

  resendAuthCode(data: ResendAuthCodeRequest) {
    return this.http.post<ResendAuthCodeResponse>(environment.apiUrl + '/resend-auth-code', data);
  }

  authRequest(data: AuthRequestRequest) {
    return this.http.post<AuthRequestResponse>(environment.apiUrl + '/auth-request', data);
  }

  authConfirm(data: AuthConfirmRequest) {
    return this.http.post<AuthConfirmResponse>(environment.apiUrl + '/auth-confirm', data);
  }

  storeToken(token:string){
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token') ?? null;
  }
}
