import { Component } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app--unauthorized-base',
  templateUrl: './base.component.html',
  standalone: true,
  imports: [],
  styleUrl: './base.component.css'
})
export class BaseComponent {
  constructor(private location: Location) {
  }
  goBack() {
    this.location.back();
  }
}
